<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.7071 9.29292C16.0977 9.68345 16.0977 10.3166 15.7071 10.7071C15.3166 11.0977 14.6834 11.0977 14.2929 10.7071L12.0002 8.4144V26C12.0002 26.5523 11.5525 27 11.0002 27C10.4479 27 10.0002 26.5523 10.0002 26V8.41403L7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289L10.2929 5.29289C10.345 5.24079 10.4022 5.19504 10.4635 5.15612C10.5983 5.07019 10.7551 5.01569 10.9236 5.00292C10.9489 5.00098 10.9744 5 11 5C11.0174 5 11.0347 5.00045 11.0519 5.00135C11.3126 5.01463 11.547 5.12777 11.7175 5.30329L15.7071 9.29292Z"
		></path>
		<path
			d="M17.7074 21.2929L20.0002 23.5857V6.00003C20.0002 5.44775 20.4479 5.00003 21.0002 5.00003C21.5525 5.00003 22.0002 5.44775 22.0002 6.00003L22.0002 23.586L24.2932 21.2929C24.6837 20.9024 25.3169 20.9024 25.7074 21.2929C26.098 21.6834 26.098 22.3166 25.7074 22.7071L21.7075 26.7072C21.65 26.7647 21.5861 26.8145 21.5176 26.8559C21.3929 26.9315 21.2503 26.9806 21.0977 26.9953C21.0655 26.9985 21.033 27.0001 21.0004 27.0001C20.9782 27.0001 20.9561 26.9993 20.9341 26.9979C20.6791 26.9813 20.4502 26.8691 20.2828 26.6968L16.2932 22.7071C15.9027 22.3166 15.9027 21.6835 16.2932 21.2929C16.6838 20.9024 17.3169 20.9024 17.7074 21.2929Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconSort",
});
</script>
